<template>
  <section class="features">
    <div class="container text-white">
      <div class="row" v-if="feature">
        <div class="col-lg-5 col-md-5">
          <div>
            <h2 style="color: #fff">{{ feature.analyticstitle }}</h2>
          </div>
        </div>
        <div class="col-lg-7 col-md-7">
          <p>{{ feature.analyticssubtitle }}</p>
        </div>
      </div>
      <div class="row pt-4" v-if="feature">
        <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch">
          <div class="icon-box">
            <ICON_Cube :size="icon_size" :color="footer_color" />
            <h3 style="color: #fff">{{ feature.analytics_title_1 }}</h3>
            <p>{{ feature.analytics_description_1 }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch">
          <div class="icon-box">
            <ICON_Cube :size="icon_size" :color="footer_color" />
            <h3 style="color: #fff">{{ feature.analytics_title_2 }}</h3>
            <p>{{ feature.analytics_description_2 }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch">
          <div class="icon-box">
            <ICON_Cube :size="icon_size" :color="footer_color" />
            <h3 style="color: #fff">{{ feature.analytics_title_3 }}</h3>
            <p>{{ feature.analytics_description_3 }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch">
          <div class="icon-box">
            <ICON_Cube :size="icon_size" :color="footer_color" />
            <h3 style="color: #fff">{{ feature.analytics_title_4 }}</h3>
            <p>{{ feature.analytics_description_4 }}</p>
          </div>
        </div>
      </div>
      <div class="pt-4">
        <Button
          :text="buttontText"
          @click.prevent="goTo(feature.contact_us_form)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Button from "@/components/Button.vue";
import ICON_Cube from "@/assets/icons/Cube.vue";
export default {
  props: {
    feature: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      buttontText: "Get Started Now",
      footer_color: "white",
      icon_size: 40,
    };
  },
  methods: {
    goTo(url) {
      if (!url) {
        return;
      }
      window.open(url);
    },
  },
  components: {
    ICON_Cube,
    Button,
  },
};
</script>

<style scoped>
.header-buttons {
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.65rem 2rem;
  line-height: 1.6rem;
  border-radius: 56px;
  min-width: 10rem;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}
img.service-image {
  width: 100%;
  margin: 10px;
}
@media (max-width: 991px) {
  .features {
    padding: 30px !important;
  }
}
.features {
  padding: 50px 0;
  background: #3f3f3f;
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.features .icon-box h3 {
  font-weight: 700;
  font-size: 15px;
  margin-top: 30px;
  margin-left: 45px;
}
.features .icon-box p {
  line-height: 24px;
  margin-bottom: 0;
  margin-left: 45px;
}
.icon-box svg {
  float: left;
  margin-top: 30px;
}
</style>
